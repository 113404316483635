import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/pic01.png'
import pic02 from '../assets/images/pic02.png'
import pic03 from '../assets/images/pic03.png'
import pic04 from '../assets/images/pic04.png'
import pic05 from '../assets/images/pic05.png'
import avatar from '../assets/images/avatar.png'
import avatar2 from '../assets/images/avatar2.png'
import avatar3 from '../assets/images/avatar3.png'
import appstore from '../assets/images/appstore.png';
import android from '../assets/images/googleplay.png';
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    const intl = this.props.intl

    return (
      <Layout>
        <Helmet
          meta={[
            {
              name: 'keywords',
              content: intl.formatMessage({ id: 'keywords' })
            },
            {
              name: `description`,
              content: intl.formatMessage({ id: 'desc' })
            }
          ]}
          title={intl.formatMessage({ id: 'title' })}
        />

        <Header intl={intl}/>

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">

            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2><FormattedMessage id="feature1" /></h2>
                </header>
                <p>
                  <FormattedMessage id="feature1Desc" />
                </p>
              </div>
              <span className="image">
                <img src={pic01} alt="" />
              </span>
            </div>

            <div className="spotlight spotlight-reverse">
              <div className="content">
                <header className="major">
                  <h2><FormattedMessage id="feature2" /></h2>
                </header>
                <p>
                  <FormattedMessage id="feature2Desc" />
                </p>
              </div>
              <span className="image">
                <img src={pic02} alt="" />
              </span>
            </div>

            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2><FormattedMessage id="feature3" /></h2>
                </header>
                <p>
                  <FormattedMessage id="feature3Desc" />
                </p>
              </div>
              <span className="image">
                <img src={pic03} style={{ imageRendering: "auto" }} alt="" />
              </span>
            </div>

            <div className="spotlight spotlight-reverse">
              <div className="content">
                <header className="major">
                  <h2><FormattedMessage id="feature4" /></h2>
                </header>
                <p>
                  <FormattedMessage id="feature4Desc" />
                </p>
              </div>
              <span className="image">
                <img src={pic04} alt="" />
              </span>
            </div>

            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2><FormattedMessage id="feature5" /></h2>
                </header>
                <p>
                  <FormattedMessage id="feature5Desc" />
                </p>
              </div>
              <span className="image">
                <img src={pic05} alt="" />
              </span>
            </div>

          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2><FormattedMessage id="testimonials" /></h2>
            </header>
            <ul className="features">
              <li>
                <img className="user-avatar" src={avatar} />
                <h3>zx0801</h3>
                <p>
                  <FormattedMessage id="comment1" />
                </p>
              </li>
              <li>
                <img className="user-avatar" src={avatar2} />
                <h3>LouisWu</h3>
                <p>
                  <FormattedMessage id="comment2" />
                </p>
              </li>
              <li>
                <img className="user-avatar" src={avatar3} />
                <h3>Martin_001</h3>
                <p>
                  <FormattedMessage id="comment3" />
                </p>
              </li>
            </ul>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2><FormattedMessage id="download" /></h2>
              <p>
                <FormattedMessage id="downloadDesc1" />
              </p>
              <div className="downloads">
                <a href="https://apps.apple.com/app/id1602939186" target="_blank">
                  <img src={appstore} alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.pxmage.zeniresume" target="_blank">
                  <img src={android} alt="" />
                </a>
              </div>
              {intl.locale === 'zh' &&         
                <div className="china-download">
                    国内安卓版可以从<a href="https://www.yuque.com/pocv40/in74g9/ayeesz" target="_blank">这里</a>下载
                </div>
              }
            </header>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2><FormattedMessage id="about" /></h2>
              <p>
                <FormattedMessage id="aboutDesc1" /><a target="_blank" href="https://egorss.com">Ego Reader</a>。
                <br />
                {intl.locale === 'zh' && 
                  <>如果对我感兴趣，可以来<a target="_blank" href="https://okjk.co/VEAniJ">即刻</a>关注我，也可以看看我的<a target="_blank" href="https://pxmage.com">博客</a>。</>
                }
              </p>
            </header>
          </section>
        </div>
      </Layout>
    )
  }
}


export default injectIntl(Index)
